<template>
  <div>
    <card-container>
      <div slot="left">
        <div>
          <div class="header-text">
            <h1 class="textOrange--text display-1 py-2">
              {{ !isElevation ? cardData.title : "Elevation Module" }}
            </h1>
            <p class="title body-1 font-weight-light">
              Welcome Card has been sent to YOU from
              <span class="textOrange--text">your company</span>
            </p>
          </div>
          <div class="body-1 font-weight-light">
            A
            <span class="textOrange--text">Welcome Card</span> is a Digital Card
            which serves as a Welcoming Message for a new person who is about to
            join the company, based on the preference of the
            <span class="textOrange--text">Account Owner</span> it could Contain
            not only a Welcoming Message but it could have some Important
            Information about the company like Vision, Mission, Location and
            More
          </div>
        </div>
      </div>
      <div slot="right">
        <div class="ml-12">
          <welcome-card-preview
            :cardData="cardData"
            :cardLinks="cardLinks"
            :isUser="true"
          />
        </div>
      </div>
    </card-container>
    <bz-footer
      text="Click on Continue Button Skip Welcome Page"
      buttonTitle="Continue"
      :to="!isElevation ? 'introduction' : 'user-training' "
    ></bz-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import CardContainer from "../../../components/CardContainer";
import WelcomeCardPreview from "../../../../../beez-admin/modules/on-boarding/pages/WelcomeCardPreview";
export default {
  components: {
    CardContainer,
    WelcomeCardPreview,
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
  },
  methods: {
    ...mapActions("welcome", {
      getWelcomeCard: "getWelcomeCard",
      elevationWelcomeSeen: "elevationWelcomeSeen",
    }),
    setCard(title, description, file, links) {
      // console.log(title, description, file, links);
      this.cardData.title = title;
      this.cardData.description = description;
      if (file) {
        this.cardData.media = {
          id: file._id,
          type: file.contentType,
          url: file.url,
        };
        this.cardData.mediaType = file.contentType;
        this.cardData.mediaUrl = file.url;
      }

      this.cardLinks.link1 = links[0];
      this.cardLinks.link2 = links[1];
      this.cardLinks.link3 = links[2];
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   if (to.name == "elevation-welcome") {
  //     next(vm => {
  //       // access to component instance via `vm`
  //       vm.isElevation = true;
  //     });
  //   } else {
  //     next();
  //   }
  // },
  beforeDestroy() {
    if (this.isElevation) {
      this.elevationWelcomeSeen();
    }
  },
  data: function() {
    return {
      isElevation: false,
      cardData: {
        title: "",
        description: "",
        media: "",
        mediaType: "",
        mediaUrl: "",
      },
      cardLinks: {
        link1: "",
        link2: "",
        link3: "",
      },
    };
  },
  async created() {
    let accountId = this.currentUser.roles[0].accountId;

    if (this.$router.currentRoute.name == "elevation-welcome") {
      this.isElevation = true;
    } else {
      this.isElevation = false;
    }

    if (this.isElevation) {
      const welcomeCards = await this.getWelcomeCard({
        accountId: accountId,
      });
      let { title, description, file, links } = welcomeCards[1];
      this.setCard(title, description, file, links);
    } else {
      let [{ title, description, file, links }] = await this.getWelcomeCard({
        accountId: accountId,
      });
      this.setCard(title, description, file, links);
    }
  },
};
</script>
